import React, { useMemo } from "react";

import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import IntegrationServices from "const/IntegrationServices";
import Select from "nlib/ui/Select";

const SelectIntegrationService = ({ filter, ...props }) => {
  const { uiTexts } = useSelector(getTextsData);

  const options = useMemo(() => {
    return [
      ...IntegrationServices
        .getList()
        .filter(({ fullIntegration }) => {
          return fullIntegration;
        })
        .map(({ value, label }) => ({ value, label })),
      filter && { value: null, label: uiTexts.all }
    ].filter(Boolean);
  }, [filter, uiTexts.all]);

  return (
    <Select
      placeholder={uiTexts.selectSystem}
      options={options}
      {...props} />
  );
};

export default React.memo(SelectIntegrationService);
