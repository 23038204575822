import React, { useMemo } from "react";

import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import DataConstants from "const/DataConstants";
import Select from "nlib/ui/Select";

const { USER_STATUSES: { APPROVED, PENDING } } = DataConstants;

const SelectUserStatus = ({ filter, ...props }) => {
  const { uiTexts } = useSelector(getTextsData);

  const options = useMemo(() => {
    return [
      { value: APPROVED, label: uiTexts.active },
      { value: PENDING, label: uiTexts.pending },
      filter && { value: null, label: uiTexts.all }
    ].filter(Boolean);
  }, [filter, uiTexts]);

  return (
    <Select
      placeholder={uiTexts.selectStatus}
      options={options}
      {...props} />
  );
};

export default React.memo(SelectUserStatus);
