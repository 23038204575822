import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Avatar } from "nlib/ui";
import { TableCell } from "nlib/ui/Table";
import { getActiveOrganization } from "selectors/organizations";
import { useSelector } from "react-redux";
import Constants from "const/Constants";
import React from "react";
import classNames from "classnames";

const NameCell = ({ show, className, fullName, currentUser }) => {
  const { superAdminAccess } = useSelector(getActiveOrganization);

  return (
    <TableCell
      show={show}
      title={currentUser && superAdminAccess ? "Great power comes with great responsibility!" : fullName}
      className={classNames(Css.nameCell, className)}>
      <Avatar className={Css.avatar} title={fullName} />
      <div>
        {currentUser && superAdminAccess && <Icons.Star weight="fill" />}
        <span>
          {fullName || Constants.EMPTY_PLACEHOLDER}
        </span>
      </div>
    </TableCell>
  );
};

export default React.memo(NameCell);
