import CommonCss from "nlib/common/common.module.scss";

import Css from "./style.module.scss";

import { TableCell } from "nlib/ui/Table";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import React, { useMemo } from "react";
import classNames from "classnames";

const NotificationsCell = ({ className, emailNotifications, smsNotifications }) => {
  const { uiTexts } = useSelector(getTextsData);

  const allowed = useMemo(() => {
    return [emailNotifications && uiTexts.email, smsNotifications && uiTexts.sms]
      .filter(Boolean);
  }, [emailNotifications, smsNotifications, uiTexts]);

  return (
    <TableCell
      className={classNames(Css.notificationsCell,
        allowed.length ? undefined : CommonCss.negativeText,
        className)}>
      <div>
        {allowed.length ? allowed.join(", ") : uiTexts.no}
      </div>
    </TableCell>
  );
};

export default React.memo(NotificationsCell);
