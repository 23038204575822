import Css from "./style.module.scss";

import { TableCell } from "nlib/ui/Table";
import { getActiveOrganization } from "selectors/organizations";
import { getTextsData } from "selectors/texts";
import { getUserData } from "selectors/user";
import { useSelector } from "react-redux";
import Constants from "const/Constants";
import React, { useMemo } from "react";
import UserRoles from "const/UserRoles";
import classNames from "classnames";

const RoleCell = ({ className, role, sub, email, guestUser }) => {
  const { creatorId, superAdminAccess } = useSelector(getActiveOrganization);

  const userData = useSelector(getUserData);

  const { uiTexts } = useSelector(getTextsData);

  const organizationCreator = creatorId === sub;

  const currentUser = userData.email === email;

  const content = useMemo(() => {
    if (guestUser) return uiTexts.guestAccess;
    if (organizationCreator) return uiTexts.organizationOwner;
    if (currentUser && superAdminAccess) return "God mode: ON :)";

    return role && uiTexts[UserRoles.getLangId(role)];
  }, [currentUser, guestUser, organizationCreator, role, superAdminAccess, uiTexts]);

  return (
    <TableCell className={classNames(Css.roleCell, className)}>
      <div title={content || ""}>
        {content || Constants.EMPTY_PLACEHOLDER}
      </div>
    </TableCell>
  );
};

export default React.memo(RoleCell);
