import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Modal } from "nlib/ui";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import React, { useEffect, useRef } from "react";

const CopyInviteLinkModal = ({ inviteLink, onClose }) => {
  const { uiTexts } = useSelector(getTextsData);

  const linkRef = useRef();

  useEffect(() => {
    if (window.getSelection && linkRef.current) {
      const selection = window.getSelection();

      const range = document.createRange();

      range.selectNodeContents(linkRef.current);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }, []);

  return (
    <Modal
      className={Css.copyInviteLinkModal}
      iconComponent={Icons.Copy}
      title={uiTexts.copyLink}
      onClose={onClose}>
      <div className={Css.link}>
        <code ref={linkRef}>{inviteLink}</code>
      </div>
    </Modal>
  );
};

export default React.memo(CopyInviteLinkModal);
