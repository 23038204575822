import React, { useMemo } from "react";

import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Select from "nlib/ui/Select";
import UserRoles from "const/UserRoles";

const SelectUserRole = ({ filter, ...props }) => {
  const { uiTexts } = useSelector(getTextsData);

  const options = useMemo(() => {
    return [
      ...UserRoles.getRoles().map(({ roleId, langId }) => {
        return { value: roleId, label: uiTexts[langId] };
      }),
      filter && { value: null, label: uiTexts.all }
    ].filter(Boolean);
  }, [filter, uiTexts]);

  return (
    <Select
      placeholder={uiTexts.selectRole}
      options={options}
      {...props} />
  );
};

export default React.memo(SelectUserRole);
