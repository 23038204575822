import CommonCss from "nlib/common/common.module.scss";

import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import React from "react";
import classNames from "classnames";

const BusinessStatus = ({ className, extraData }) => {
  const { uncategorizedRpaEnabled, reconciliationRpaEnabled } = extraData;

  const { uiTexts } = useSelector(getTextsData);

  const rpaMode = uncategorizedRpaEnabled !== undefined || reconciliationRpaEnabled !== undefined;

  if (rpaMode) {
    return (
      <div
        title={uiTexts.roboticAiBookkeeper}
        className={classNames(Css.businessType, className)}>
        <Icons.Cpu />
        <span className={CommonCss.neutralText}>
          {uiTexts.roboticAiBookkeeper}
        </span>
      </div>
    );
  }

  return (
    <div
      title={uiTexts.dataEntryAutomationHub}
      className={classNames(Css.businessType, CommonCss.neutralText, className)}>
      {uiTexts.dataEntryAutomationHub}
    </div>
  );
};

export default React.memo(BusinessStatus);
