import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Button } from "nlib/ui";
import { getActiveOrganization, getAllUsersData } from "selectors/organizations";
import { getTextsData } from "selectors/texts";
import { getUserBusinessesData } from "selectors/businesses";
import { getUserData, getUserRestrictions } from "selectors/user";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Badge from "nlib/ui/Badge";
import BusinessesTab from "./lib/BusinessesTab";
import OrganizationsActions from "actions/OrganizationsActions";
import Page from "nlib/common/Page";
import PageContent from "nlib/common/PageContent";
import PageHeader from "nlib/common/PageHeader";
import React, { useCallback, useMemo } from "react";
import Tabs, { Tab } from "nlib/ui/Tabs";
import UiActions from "actions/UiActions";
import UiRoutes from "const/UiRoutes";
import UsersTab from "./lib/UsersTab";
import useLocationHistory from "hooks/useLocationHistory";
import useShowCommonModal from "hooks/useShowCommonModal";

const SettingsPage = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const locationHistoryRef = useLocationHistory();

  const { section } = useParams();

  const currentRoute = section ? `/${section}` : UiRoutes.USERS;

  const { uiTexts } = useSelector(getTextsData);

  const usersData = useSelector(getAllUsersData);

  const userRestrictions = useSelector(getUserRestrictions);

  const userBusinessesData = useSelector(getUserBusinessesData);

  const userData = useSelector(getUserData);

  const activeOrganization = useSelector(getActiveOrganization);

  const showCommonModal = useShowCommonModal();

  const guestUsersData = usersData.filter((user) => user.guestUser);

  const regularUsersData = useMemo(() => {
    return usersData.filter((user) => !user.guestUser);
  }, [usersData]);

  const handleTabChange = useCallback((value) => {
    history.push(`${UiRoutes.SETTINGS}${value}`);
  }, [history]);

  const handleToAllClientClick = useCallback(() => {
    const filtered = locationHistoryRef.current.filter((item) => !item.pathname.startsWith(UiRoutes.SETTINGS));

    if (filtered.length) {
      const { pathname, search } = filtered.pop();

      history.push(`${pathname}${search || ""}`);
    } else {
      history.push(UiRoutes.MAIN);
    }
  }, [history, locationHistoryRef]);

  const handleDeleteOrganizationClick = useCallback(async() => {
    const result = await showCommonModal({
      text: `[ ${activeOrganization.name} ]\n\nAre you sure you want to perform this action?`,
      confirm: true,
      okButtonText: "YES, DELETE THIS ORGANIZATION"
    });

    if (result) {
      dispatch(UiActions.togglePreloader(true));

      const successful = await dispatch(OrganizationsActions.deleteOrganization(activeOrganization.id));

      if (successful) window.location.reload();
    }
  }, [activeOrganization.id, activeOrganization.name, dispatch, showCommonModal]);

  return (
    <Page className={Css.settingsPage}>
      <PageContent>
        <PageHeader title={activeOrganization.name} iconComponent={Icons.Gear}>
          <div className={Css.actions}>
            {!!userData.superAdmin && (
              <div className={Css.superAdminActions}>
                <Button
                  large danger outline
                  icon={Icons.TrashSimple}
                  onClick={handleDeleteOrganizationClick}>
                Delete organization
                </Button>
              </div>
            )}
            <Button
              large outline
              className={Css.backButton}
              icon={Icons.ArrowLeft}
              onClick={handleToAllClientClick}>
              {uiTexts.back}
            </Button>
          </div>
        </PageHeader>
        <Tabs className={Css.tabs} current={currentRoute} onChange={handleTabChange}>
          <Tab className={Css.tab} value={UiRoutes.USERS}>
            <Icons.Users />
            <span>{uiTexts.users}</span>
            {userRestrictions.usersRead || <Badge counter>{regularUsersData.length}</Badge>}
          </Tab>
          {(!!guestUsersData.length && !userRestrictions.usersRead) && (
            <Tab className={Css.tab} value={UiRoutes.GUEST_USERS}>
              <Icons.Users />
              <span>{uiTexts.guestUsers}</span>
              <Badge counter>{guestUsersData.length}</Badge>
            </Tab>
          )}
          <Tab className={Css.tab} value={UiRoutes.BUSINESSES}>
            <Icons.Suitcase />
            <span>{uiTexts.businesses}</span>
            <Badge counter>{userBusinessesData.length}</Badge>
          </Tab>
        </Tabs>
        {(() => {
          switch (currentRoute) {
            case UiRoutes.USERS:
              return <UsersTab />;
            case UiRoutes.GUEST_USERS:
              return <UsersTab guestUsers />;
            case UiRoutes.BUSINESSES:
              return <BusinessesTab />;
            default:
              return null;
          }
        })()}
      </PageContent>
    </Page>
  );
};

export default React.memo(SettingsPage);
