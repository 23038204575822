import Css from "./style.module.scss";

import { TableCell } from "nlib/ui/Table";
import ActionCellContent from "nlib/common/ActionCellContent";
import React, { useCallback, useMemo } from "react";
import classNames from "classnames";

const stopPropagation = (event) => event.stopPropagation();

const ActionsCell = ({ businessId, className, disabledOpenSettings, disabledDelete, onOpenSettings, onDelete }) => {
  const menuItems = useMemo(() => {
    return [
      {
        action: "settings",
        disabled: disabledOpenSettings
      },
      {
        action: "delete",
        disabled: disabledDelete
      }
    ];
  }, [disabledDelete, disabledOpenSettings]);

  const handleMenuItemClick = useCallback((action) => {
    switch (action) {
      case "settings":
        onOpenSettings(businessId);
        break;
      case "delete":
        onDelete(businessId);
        break;
      default:
        break;
    }
  }, [businessId, onDelete, onOpenSettings]);

  return (
    <TableCell className={classNames(Css.actionsCell, className)} onClick={stopPropagation}>
      <ActionCellContent
        menuItems={menuItems}
        onClick={handleMenuItemClick} />
    </TableCell>
  );
};

export default React.memo(ActionsCell);
