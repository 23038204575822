import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { TableCell } from "nlib/ui/Table";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import ActionCellContent from "nlib/common/ActionCellContent";
import React, { useCallback, useMemo } from "react";
import classNames from "classnames";

const stopPropagation = (event) => event.stopPropagation();

const ActionsCell = ({ className, email, disabledEdit, disabledRevoke, onEdit, onRevoke, onCopyInviteLink }) => {
  const { uiTexts } = useSelector(getTextsData);

  const menuItems = useMemo(() => {
    return [
      onCopyInviteLink
        ? {
          action: "copyInviteLink",
          icon: Icons.Copy,
          theme: "medium",
          text: uiTexts.copyLink
        }
        : {
          action: "edit",
          disabled: disabledEdit
        },
      {
        action: "revoke",
        disabled: disabledRevoke
      }
    ];
  }, [uiTexts, disabledEdit, disabledRevoke, onCopyInviteLink]);

  const handleMenuItemClick = useCallback((action) => {
    switch (action) {
      case "edit":
        onEdit(email);
        break;
      case "revoke":
        onRevoke(email);
        break;
      case "copyInviteLink":
        onCopyInviteLink(email);
        break;
    }
  }, [email, onEdit, onRevoke, onCopyInviteLink]);

  return (
    <TableCell className={classNames(Css.actionsCell, className)} onClick={stopPropagation}>
      <ActionCellContent
        menuItems={menuItems}
        onClick={handleMenuItemClick} />
    </TableCell>
  );
};

export default React.memo(ActionsCell);
