import Css from "./style.module.scss";

import { TableCell } from "nlib/ui/Table";
import Constants from "const/Constants";
import React from "react";
import classNames from "classnames";
import moment from "moment";

const LastLoginAtCell = ({ className, lastLoginAt }) => {
  const content = lastLoginAt
    ? moment.duration(moment.utc(lastLoginAt).diff(moment.utc())).humanize(true)
    : Constants.EMPTY_PLACEHOLDER;

  return (
    <TableCell className={classNames(Css.lastLoginAtCell, className)}>
      <div title={lastLoginAt && content}>{content}</div>
    </TableCell>
  );
};

export default React.memo(LastLoginAtCell);
