import CommonCss from "nlib/common/common.module.scss";

import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { AutoTooltip } from "nlib/ui";
import { TableCell } from "nlib/ui/Table";
import { getAllBusinessesData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import React from "react";
import classNames from "classnames";

const Content = ({ businessIds }) => {
  const { uiTexts } = useSelector(getTextsData);

  const businessesData = useSelector(getAllBusinessesData);

  if (!businessIds.length) {
    return (
      <span title={uiTexts.allAvailable}>{uiTexts.allAvailable}</span>
    );
  }

  const businessNames = businessIds
    .map((businessId) => (businessesData.find(({ id }) => id === businessId) || {}).name || "")
    .filter(Boolean)
    .sort();

  if (!businessNames.length) {
    return (
      <>
        <Icons.WarningCircle className={CommonCss.negativeText} />
        <span>{uiTexts.noBusinesses}</span>
      </>
    );
  }

  return (
    <AutoTooltip title={businessNames.join(", ")}>
      {businessNames.join(", ")}
    </AutoTooltip>
  );
};

const BusinessCell = ({ className, businessIds }) => {
  return (
    <TableCell className={classNames(Css.businessCell, className)}>
      <Content businessIds={businessIds} />
    </TableCell>
  );
};

export default React.memo(BusinessCell);
