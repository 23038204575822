import Css from "./style.module.scss";

import { TableCell } from "nlib/ui/Table";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Badge from "nlib/ui/Badge";
import DataConstants from "const/DataConstants";
import React from "react";
import classNames from "classnames";

const { PENDING, APPROVED } = DataConstants.USER_STATUSES;

const THEMES = {
  [PENDING]: "alternative",
  [APPROVED]: "positive"
};

const StatusCell = ({ className, pendingInvite }) => {
  const { uiTexts } = useSelector(getTextsData);

  return (
    <TableCell className={classNames(Css.statusCell, className)}>
      <Badge
        theme={pendingInvite ? THEMES[PENDING] : THEMES[APPROVED]}>
        {pendingInvite ? uiTexts.pending : uiTexts.active}
      </Badge>
    </TableCell>
  );
};

export default React.memo(StatusCell);
